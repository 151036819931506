<template>
  <div>
    <div class="d-flex">
      <h1 class="headline--md text-bold text-uppercase">User List</h1>

      <router-link
        v-if="$auth('Page', currentUser, $accessRule.UserUpdate).canView()"
        class="btn btn--primary"
        to="/admin/register"
        tag="button"
      >
        Add
      </router-link>
    </div>

    <div v-if="boUsers" class="table-wrapper table-wrapper--fluid table-wrapper--expand mt-16">
      <table class="table table--striped">
        <thead>
          <tr>
            <th>No.</th>
            <th>Full Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(user, index) in boUsers" :key="user.id">
            <td>{{ ++index }}</td>
            <td>{{ user.fullName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.contryCode }} {{ user.phone }}</td>
            <td>{{ user.role | format_bo_user_role_text }}</td>
            <td>
              <template
                v-if="currentUser.id !== user.id && $auth('Page', currentUser, $accessRule.UserUpdate).canView()"
              >
                <button
                  class="btn btn--clear"
                  :style="{ background: 'transparent' }"
                  @click="onOpenForceUpdateBoUserModal(user)"
                >
                  <toko-edit-icon />
                </button>
                <button class="btn btn--clear" :style="{ background: 'transparent' }" @click="onDeleteUser(user.id)">
                  <toko-trash-icon />
                </button>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center mt-32">
        <p>{{ boUsers.length }} users in total</p>
      </div>
    </div>

    <confirm-dialog
      v-if="isOpenConfirmRemoveUserDialog"
      :open.sync="isOpenConfirmRemoveUserDialog"
      confirmText="Are you sure to remove this user ?"
      confirmButtonText="Delete"
      @confirm="onConfirmDeleteUser"
    />

    <modal v-if="isForceUpdateBoUserModalOpen" modalClass="modal modal--md" :open.sync="isForceUpdateBoUserModalOpen">
      <force-update-bo-user-form :user="targetUserObject" @on-force-update-profile="onForceUpdateBoProfile($event)" />
    </modal>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import toggleLoading from '@/mixins/toggle-loading'
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const TokoEditIcon = () => import('@/ui/toko-icons/TokoEdit')
const TokoTrashIcon = () => import('@/ui/toko-icons/TokoTrash')
const Modal = () => import('@/components/Modal')
const ForceUpdateBoUserForm = () => import('@/components/ForceUpdateBoUserForm')

export default {
  data() {
    return {
      isOpenConfirmRemoveUserDialog: false,
      isForceUpdateBoUserModalOpen: false,
      targetUserId: 0,
      targetUserObject: null
    }
  },

  mixins: [toggleLoading],

  components: {
    ConfirmDialog,
    TokoEditIcon,
    TokoTrashIcon,
    Modal,
    ForceUpdateBoUserForm
  },

  computed: {
    ...mapState('boUsers', ['boUsers'])
  },

  methods: {
    ...mapActions('boUsers', ['getBoUsers', 'deleteBoUser', 'forceUpdateBoUser']),

    onConfirmDeleteUser() {
      this.toggleLoading()
      this.deleteBoUser(this.targetUserId)
        .then(() => {
          this.isOpenConfirmRemoveUserDialog = false
        })
        .finally(() => {
          this.toggleLoading()
        })
    },

    onDeleteUser(id) {
      this.isOpenConfirmRemoveUserDialog = true
      this.targetUserId = id
    },

    onOpenForceUpdateBoUserModal(user) {
      this.targetUserObject = user
      this.isForceUpdateBoUserModalOpen = true
    },

    onForceUpdateBoProfile(user) {
      this.toggleLoading()
      this.forceUpdateBoUser({
        id: this.targetUserObject.id,
        dataRequest: this.getUpdateBoProfileRequest(user)
      })
        .then(() => {
          this.isForceUpdateBoUserModalOpen = false
        })
        .finally(() => {
          this.toggleLoading()
        })
    },

    getUpdateBoProfileRequest(user) {
      return {
        email: user.email,
        fullName: user.fullName,
        username: user.username,
        role: parseInt(user.role)
      }
    }
  },

  created() {
    this.getBoUsers().finally(() => {
      this.toggleLoading()
    })
  }
}
</script>
